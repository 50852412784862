import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    work: {},
    frontpage: {},
    pages: {},
    news: {}
  },
  mutations: {
    setWork(state, payload) {
      state.work = payload;
    },
    setFrontpage(state, payload) {
      state.frontpage = payload;
    },
    setPages(state, payload) {
      state.pages = payload;
    },
    setNews(state, payload) {
      state.news = payload;
    }
  },
  actions: {
    readEntries({ commit }) {
      Vue.axios.get("https://megapop.no/admin/category/kundecase/feed/json").then(response => {
        commit("setWork", response.data.items);
      });
      Vue.axios.get("https://megapop.no/admin/category/kundecase/forsiden/feed/json").then(response => {
        commit("setFrontpage", response.data.items);
      });
      Vue.axios.get("https://megapop.no/admin/category/side-megapop/feed/json").then(response => {
        commit("setPages", response.data.items);
      });
      Vue.axios.get("https://megapop.no/admin/category/nyhet/megapop-no/feed/json").then(response => {
        commit("setNews", response.data.items);
      });
    }
  }
});

export default store;
