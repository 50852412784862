import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import "./assets/css/_index.scss";

Vue.mixin({
  mounted() {},
  methods: {}
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    store.dispatch("readEntries");
  }
}).$mount("#app");
