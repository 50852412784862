import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/Home2.vue")
  },
  {
    path: "/omoss",
    component: () => import("../views/About.vue")
  },
  {
    path: "/kundecaser",
    component: () => import("../views/Work.vue")
  },
  {
    path: "/kontakt",
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/:id",
    component: () => import("../views/Page.vue"),
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      let element = document.querySelector(to.hash);
      if (element) {
        return window.scrollTo({
          top: element.offsetTop - 100,
          behavior: "smooth"
        });
      } else {
        setTimeout(function() {
          element = document.querySelector(to.hash);

          return window.scrollTo({
            top: element.offsetTop - 100,
            behavior: "smooth"
          });
        }, 500);
      }
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
