<template>
  <div id="app">
    <Nav />

    <article>
      <transition name="slide-transition" mode="out-in">
        <router-view></router-view>
      </transition>
    </article>

    <Footer />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {
    Nav: () => import("@/views/components/Nav.vue"),
    Footer: () => import("@/views/components/Footer.vue")
  }
};
</script>

<style lang="scss" scoped>
article {
  margin-top: 64px;
}
</style>
